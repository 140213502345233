/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pronájem"}>
        <SiteHeader />

        <Column className="css-mvyc9w css-42e4bw --center pb--80 pt--80" name={"ad1k06pq6h8"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_bl=1_ove=120a34x70__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--color-custom-1--95);\">Pronájem Tesla Model Y LONG RANGE</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"MOŽNO ZAKOUPIT PŘÍMO JAKO DÁRKOVÝ POUKAZ V SEKCI \"Zážitková jízda\"<br>Vyzkoušejte naší Y-lonku a zažijte 514koní na vlastní kůži včetně autopilota!\n"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--16" name={"sluzby"} style={{"paddingBottom":74}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--stretch" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"<span style=\"color: var(--color-supplementary);\">Pronájem 1 den</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"6000 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"<span style=\"color: var(--color-supplementary);\">Pronájem 2 dny</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"7 990 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"<span style=\"color: var(--color-supplementary);\">Pronájem 3 dny</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"11200 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"<span style=\"color: var(--color-supplementary);\">Pronájem 4 a více dní</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"Individuální nabídka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"<span style=\"color: var(--color-supplementary);\">Měsíc</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"50000 Kč, 3000km v ceně"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":0}} content={"Podmínky pronájmu"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":8}} content={"V ceně není energie, palivo, při navrácení proběhne vyúčtování podle spotřeby/ceny čerpání.<br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":8}} content={"Denní limit 300 km nájezdu, nadlimitní kilometry stojí 7,26 Kč/km, chybějící 1% baterie stoji 13Kč.&nbsp;<br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":8}} content={"Vratná kauce 25000 Kč<br>UHRAZENÉ ZÁLOHY NA PRONÁJEM JSOU V PŘÍPADĚ ZRUŠENÍ ZE STRANY NÁJEMCE <span style=\"font-weight: bold;\">NEVRATNÉ<br></span>CENY UVEDENY S DPH<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1" anim={"2"} animS={"3"} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"pphn3wuscs"} style={{"paddingTop":40,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Poptat pronájem"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--30" name={"paticka"} style={{"paddingBottom":18,"backgroundColor":"rgba(32, 32, 32, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kontakt\n</span>"}>
              </Title>

              <Text className="text-box fs--16" content={"<fontsninja-text id=\"fontsninja-text-258\" class=\"fontsninja-family-2033\">+420 555 222 451<br></fontsninja-text>BESKYD servis spol. s r.o.<br>Revoluční 149&nbsp;<br>739 11 Frýdlant n.O.<br>IČ 27800814<br>DIČ CZ27800814<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--16" style={{"marginTop":0}} content={"www.botesla.cz<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"boteslacz@gmail.com<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":7}} content={"<span style=\"color: var(--color-blend--15);\">GDPR | <a href=\"https://cdn.swbpg.com/d/27050/vseobecne-obchodni-podminky.pdf\">Obchodní podmínky</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}